var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c(
            "div",
            { staticClass: "filter-bar boxarea topic-filter" },
            [_c("SelectLayer")],
            1
          ),
          _c("section", { staticClass: "competition1" }, [
            _c(
              "div",
              { staticClass: "boxarea" },
              [
                _c("p", [_vm._v(_vm._s(_vm.prodNameA_source))]),
                _c("p", [_vm._v(_vm._s(_vm.prodNameA))]),
                _c("span", [
                  _vm._v(_vm._s(_vm.totalA) + " " + _vm._s(_vm.$t("reviews")))
                ]),
                _c("Simple-Donut", { attrs: { series: _vm.competitorA } }),
                _c(
                  "div",
                  { staticClass: "confidence" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("review_credibility")))]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: _vm.credibilityA,
                        format: _vm.format
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.getScore(_vm.credibilityA)))])
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleReviews(0, _vm.prodNameA)
                      }
                    }
                  },
                  [
                    _vm.loading == 0
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c("i", { staticClass: "el-icon-document" })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea" },
              [
                _c("p", [_vm._v(_vm._s(_vm.prodNameB_source))]),
                _c("p", [_vm._v(_vm._s(_vm.prodNameB))]),
                _c("span", [
                  _vm._v(_vm._s(_vm.totalB) + " " + _vm._s(_vm.$t("reviews")))
                ]),
                _c("Simple-Donut", { attrs: { series: _vm.competitorB } }),
                _c(
                  "div",
                  { staticClass: "confidence" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("review_credibility")))]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: _vm.credibilityB,
                        format: _vm.format
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.getScore(_vm.credibilityB)))])
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleReviews(1, _vm.prodNameB)
                      }
                    }
                  },
                  [
                    _vm.loading == 1
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c("i", { staticClass: "el-icon-document" })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea" },
              [
                _c("p", [_vm._v(_vm._s(_vm.prodNameC_source))]),
                _c("p", [_vm._v(_vm._s(_vm.prodNameC))]),
                _c("span", [
                  _vm._v(_vm._s(_vm.totalC) + " " + _vm._s(_vm.$t("reviews")))
                ]),
                _c("Simple-Donut", { attrs: { series: _vm.competitorC } }),
                _c(
                  "div",
                  { staticClass: "confidence" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("review_credibility")))]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: _vm.credibilityC,
                        format: _vm.format
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.getScore(_vm.credibilityC)))])
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleReviews(2, _vm.prodNameC)
                      }
                    }
                  },
                  [
                    _vm.loading == 2
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c("i", { staticClass: "el-icon-document" })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea" },
              [
                _c("p", [_vm._v(_vm._s(_vm.prodNameD_source))]),
                _c("p", [_vm._v(_vm._s(_vm.prodNameD))]),
                _c("span", [
                  _vm._v(_vm._s(_vm.totalD) + " " + _vm._s(_vm.$t("reviews")))
                ]),
                _c("Simple-Donut", { attrs: { series: _vm.competitorD } }),
                _c(
                  "div",
                  { staticClass: "confidence" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("review_credibility")))]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: _vm.credibilityD,
                        format: _vm.format
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.getScore(_vm.credibilityD)))])
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleReviews(3, _vm.prodNameD)
                      }
                    }
                  },
                  [
                    _vm.loading == 3
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c("i", { staticClass: "el-icon-document" })
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "section",
            { staticClass: "competition2" },
            _vm._l(_vm.productFactors, function(data, k) {
              return _c(
                "div",
                { key: k, staticClass: "boxarea" },
                [
                  _c("div", { staticClass: "box-title" }, [
                    _c("h2", [
                      _vm._v(
                        " " +
                          _vm._s(
                            data._id === 1
                              ? "General"
                              : data._id === 2
                              ? "Size"
                              : data._id === 3
                              ? "Material"
                              : data._id === 4
                              ? "Quality"
                              : data._id === 5
                              ? "Color"
                              : data._id === 6
                              ? "Price"
                              : data._id === 7
                              ? "Picture"
                              : data._id === 8
                              ? "Style"
                              : data._id === 9
                              ? "Brand"
                              : data._id === 10
                              ? "Shipping"
                              : data._id === 11
                              ? "Durability"
                              : data._id === 12
                              ? "Problem"
                              : null
                          ) +
                          " "
                      )
                    ]),
                    _c("h2", [_vm._v("Positive Rate")])
                  ]),
                  _vm._l(data[_vm.prodNameA], function(item, j) {
                    return _c("div", { key: j }, [
                      data[_vm.prodNameA]
                        ? _c(
                            "div",
                            { staticClass: "confidence-progress" },
                            [
                              _c("p", [_vm._v(_vm._s(item.product))]),
                              _c("el-progress", {
                                attrs: {
                                  "text-inside": true,
                                  "stroke-width": 20,
                                  percentage: Math.round(
                                    (item.total / item.sum) * 100
                                  ),
                                  color: "#6B93CC"
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    Math.round((item.total / item.sum) * 100)
                                  ) + "% "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  }),
                  _vm._l(data[_vm.prodNameB], function(item, l) {
                    return _c("div", { key: l }, [
                      _c(
                        "div",
                        { staticClass: "confidence-progress" },
                        [
                          _c("p", [_vm._v(_vm._s(item.product))]),
                          _c("el-progress", {
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 20,
                              percentage: Math.round(
                                (item.total / item.sum) * 100
                              ),
                              color: "#76B9E2"
                            }
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                Math.round((item.total / item.sum) * 100)
                              ) + "% "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  }),
                  _vm._l(data[_vm.prodNameC], function(item, m) {
                    return _c("div", { key: m }, [
                      _c(
                        "div",
                        { staticClass: "confidence-progress" },
                        [
                          _c("p", [_vm._v(_vm._s(item.product))]),
                          _c("el-progress", {
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 20,
                              percentage: Math.round(
                                (item.total / item.sum) * 100
                              ),
                              color: "#B8378F"
                            }
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                Math.round((item.total / item.sum) * 100)
                              ) + "% "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  }),
                  _vm._l(data[_vm.prodNameD], function(item, n) {
                    return _c("div", { key: n }, [
                      _c(
                        "div",
                        { staticClass: "confidence-progress" },
                        [
                          _c("p", [_vm._v(_vm._s(item.product))]),
                          _c("el-progress", {
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 20,
                              percentage: Math.round(
                                (item.total / item.sum) * 100
                              ),
                              color: "#98C93C"
                            }
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                Math.round((item.total / item.sum) * 100)
                              ) + "% "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              )
            }),
            0
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.modalVisible
              ? _c(
                  "div",
                  { staticClass: "dim" },
                  [
                    _c("ReviewDetail2", {
                      attrs: {
                        reviewList: _vm.reviewList,
                        name: _vm.productName
                      },
                      on: { close: _vm.close }
                    }),
                    _c("div", {
                      staticClass: "dimbg",
                      on: { click: _vm.close }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }